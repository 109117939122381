import { components } from 'openapi-types';
import { LocationCell } from '../common/LocationCell';

interface SiteLocationCellProps {
  readonly site: components['schemas']['Site'];
}

export const SiteLocationCell = ({ site }: SiteLocationCellProps) => {
  const location = site.location?.split(' - ') ?? ['', 'Unknown'];
  return <LocationCell code={location[0]} label={location.join(' \u00b7 ')} />;
};
